<template>
  <div class="homeWrapper">
    <div v-if="!isMobile" class="pcWrapper">
      <div class="firstBannerWrapper">
        <div class="block">
          <el-carousel :interval="3000" width="100%" :height="imgHeight" >
            <el-carousel-item
              v-for="(item, index) in firstDataList"
              :key="index + 1"
            >
              <img class="scrllImg" :src="item.imgUrl" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="secondWrapper">
        <div class="secontTitle">我们能为您的行业打造深度的数字化能力</div>
        <div class="secContent">
          <div class="secTopRow">
            <div
              class="secItem"
              v-for="(item, index) in secondDataList1"
              :key="index"
            >
              <img class="showImage" :src="item.imgUrl" alt="" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="secBottonRow">
            <div
              class="secItem"
              v-for="(item, index) in secondDataList2"
              :key="index + 10"
            >
              <img class="showImage" :src="item.imgUrl" alt="" />
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="thirdWrapper">
        <img src="../../assets/images/home/thirdback.png" alt="" />
        <div class="thirdTitle">我们的市场表现</div>
        <div class="thirdContent">
          2020年7月，艾融软件作为全国第一批晋级精选层的新三板企业，迈开了在资本市场新的一步。
        </div>
      </div>
      <div class="forthWrapper">
        <div class="forthTitle">我们的技术实力</div>
        <div class="forthContent">
          <div class="forthRow">
            <div
              class="forthRowItem"
              v-for="(item, index) in forthDataList"
              :key="index + 20"
            >
              <div class="itemTitle">{{ item.title }}</div>
              <img :src="item.imgUrl" alt="" />
              <div class="itemBottomName">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="fifthWrapper">
        <img src="../../assets/images/home/fifthback.png" alt="" />
        <div class="fifthTitle">您可以信赖这样的一支队伍</div>
      </div>
      <div class="sixWrapper">
        <div class="sixTitle">全国机构</div>
        <div class="sixContent">
          <div class="rightImg">
            <img src="../../assets/images/home/six03.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="m_wrapper">
      <div class="m_firstBannerWrapper">
        <div class="block">
          <el-carousel :interval="3000" width="100%" height="530px">
            <el-carousel-item
              v-for="(item, index) in m_firstDataList"
              :key="index + 1"
            >
              <img :src="item.imgUrl" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="m_secondWrapper">
        <div class="secontTitle">我们能为您的行业打造深度的数字化能力</div>
        <div class="secContent">
          <div class="secTopRow">
            <div
              class="secItem"
              v-for="(item, index) in m_secondDataList1"
              :key="index"
            >
              <img class="showImage" :src="item.imgUrl" alt="" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="secBottonRow">
            <div
              class="secItem"
              v-for="(item, index) in m_secondDataList2"
              :key="index + 10"
            >
              <img class="showImage" :src="item.imgUrl" alt="" />
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="m_thirdTitle">我们的市场表现</div>
      <div class="m_thirdWrapper">
        <img src="../../assets/images/home/phone/thirdback.png" alt="" />
        <div class="thirdContent">
          2020年7月，艾融软件作为全国第一批晋级精选层的新三板企业，迈开了在资本市场新的一步。
        </div>
      </div>
      <div class="m_forthWrapper">
        <div class="m_forthTitle">我们的技术实力</div>
        <div class="m_forthContent">
          <div class="m_forthRow">
            <div
              class="m_forthRowItem"
              v-for="(item, index) in forthDataList"
              :key="index + 20"
            >
              <div class="m_itemTitle">{{ item.title }}</div>
              <img :src="item.imgUrl" alt="" />
              <div class="m_itemBottomName">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="m_fifthWrapper">
        <div class="m_fifthTitle">您可以信赖这样的一支队伍</div>
        <div class="m_fifthContent">
          <div
            class="m_fifthItem"
            v-for="(item, index) in m_fifthDataList"
            :key="index + 80"
          >
            <img :src="item.imgUrl" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { isMobile } from "../../utils/index";

export default {
  name: "First",
  data() {
    return {
      screenWidth: document.body.clientWidth,     // 屏幕宽
      isMobile: false,
      m_firstDataList: [
        {
          id: "1",
          imgUrl: require("../../assets/images/home/phone/banner/banner1.png"),
          // imgUrl: banner1
        },
        {
          id: "2",
          imgUrl: require("../../assets/images/home/phone/banner/banner2.png"),
          // imgUrl: banner2
        },
        {
          id: "3",
          imgUrl: require("../../assets/images/home/phone/banner/banner3.png"),
          // imgUrl: banner3
        },
        {
          id: "4",
          imgUrl: require("../../assets/images/home/phone/banner/banner4.png"),
          // imgUrl: banner4
        },
         {
          id: "5",
          imgUrl: require("../../assets/images/home/phone/banner/banner5.png"),
          // imgUrl: banner1
        },
      ],
      firstDataList: [
        {
          id: "1",
          imgUrl: require("../../assets/images/home/banner/banner1.png"),
          // imgUrl: banner1
        },
        {
          id: "2",
          imgUrl: require("../../assets/images/home/banner/banner2.png"),
          // imgUrl: banner1
        },
        {
          id: "3",
          imgUrl: require("../../assets/images/home/banner/banner3.png"),
          // imgUrl: banner2
        },
        {
          id: "4",
          imgUrl: require("../../assets/images/home/banner/banner4.png"),
          // imgUrl: banner3
        },
        {
          id: "5",
          imgUrl: require("../../assets/images/home/banner/banner5.png"),
          // imgUrl: banner4
        },
      ],
      m_secondDataList1: [
        {
          id: "13",
          name: "产品及解决方案",
          imgUrl: require("../../assets/images/home/phone/second01.png"),
        },
        {
          id: "7",
          name: "业务咨询和创新",
          imgUrl: require("../../assets/images/home/phone/second02.png"),
        },
        {
          id: "1",
          name: "大规模定制交付",
          imgUrl: require("../../assets/images/home/phone/second03.png"),
        },
      ],
      m_secondDataList2: [
        {
          id: "20",
          name: "数字化运营",
          imgUrl: require("../../assets/images/home/phone/second04.png"),
        },
        {
          id: "21",
          name: "数字化安全保障",
          imgUrl: require("../../assets/images/home/phone/second05.png"),
        },
        {
          id: "22",
          name: "数字化系统集成",
          imgUrl: require("../../assets/images/home/phone/second06.png"),
        },
      ],
      secondDataList1: [
        {
          id: "13",
          name: "产品及解决方案",
          imgUrl: require("../../assets/images/home/second01.png"),
        },
        {
          id: "7",
          name: "业务咨询和创新",
          imgUrl: require("../../assets/images/home/second02.png"),
        },
        {
          id: "1",
          name: "大规模定制交付",
          imgUrl: require("../../assets/images/home/second03.png"),
        },
      ],
      secondDataList2: [
        {
          id: "20",
          name: "数字化运营",
          imgUrl: require("../../assets/images/home/second04.png"),
        },
        {
          id: "21",
          name: "数字化安全保障",
          imgUrl: require("../../assets/images/home/second05.png"),
        },
        {
          id: "22",
          name: "数字化系统集成",
          imgUrl: require("../../assets/images/home/second06.png"),
        },
      ],
      forthDataList: [
        {
          id: "13",
          title: "90%",
          name: "技术工程师",
          imgUrl: require("../../assets/images/home/forth01.png"),
        },
        {
          id: "7",
          title: "315项",
          name: "计算机软件著作权",
          imgUrl: require("../../assets/images/home/forth02.png"),
        },
        {
          id: "1",
          title: "19项", 
          name: "软件技术发明专利",
          imgUrl: require("../../assets/images/home/forth03.png"),
        },
      ],
      m_fifthDataList: [
        {
          id: "1",
          imgUrl: require("../../assets/images/home/phone/fifth/fifth01.png"),
        },
        {
          id: "2",
          imgUrl: require("../../assets/images/home/phone/fifth/fifth02.png"),
        },
        {
          id: "3",
          imgUrl: require("../../assets/images/home/phone/fifth/fifth03.png"),
        },
        {
          id: "4",
          imgUrl: require("../../assets/images/home/phone/fifth/fifth04.png"),
        },
        {
          id: "5",
          imgUrl: require("../../assets/images/home/phone/fifth/fifth05.png"),
        },
        {
          id: "6",
          imgUrl: require("../../assets/images/home/phone/fifth/fifth06.png"),
        },
      ],
    };
  },
  computed: {
    // 计算banner高度
    imgHeight(){
      return 788/ 1920 * this.screenWidth + 'px';
    }
  },
  created() {},
  mounted() {
    this.isMobile = isMobile();
    window.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },

  methods: {
    getScroll() {
      // this.indexNumTop = $("#index_num").offset().top;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
      ) {
        //设置滚动大于300时的执行内容
        // console.log(
        //   "document.documentElement.scrollTop=",
        //   document.documentElement.scrollTop
        // );
        this.$store.commit("TO_AfterScroll");
      } else {
        this.$store.commit("TO_AfterScroll_false");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.homeWrapper {
  background: #fff;
  .pcWrapper {
    .firstBannerWrapper {
      .el-carousel__container{
        width: 100%;
        height: 100%;
      }
      img {
        /*设置图片宽度和浏览器宽度一致*/
        width: 100%;
        height: inherit;
      }
    }
    .secondWrapper {
      .secontTitle {
        text-align: center;
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 50px;
      }
      .secContent {
        width: 75%;
        margin: 24px auto;
        height: 400px;
        // background: green;
        .secTopRow {
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;
          font-size: 16px;
          // background: #ae3938;
          .secItem {
            width: 150px;
            .showImage {
              width: 150px;
              height: 150px;
            }
          }
        }
        .secBottonRow {
          // background: green;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          margin-bottom: 40px;
          .secItem {
            width: 150px;
            // width:
            .showImage {
              width: 150px;
              height: 150px;
            }
          }
        }
      }
    }
    .thirdWrapper {
      margin-top: 80px;
      overflow: hidden;
      position: relative;
      img {
        float: left;
        width: 100%;
        height: auto;
      }
      .thirdTitle {
        text-align: center;
        position: absolute;
        top: 24px;
        left: 0;
        width: 100%;
        height: 30px;
        z-index: 5;
        color: #fff;
        font-size: 30px;
      }
      .thirdContent {
        font-family: PingFangSC-Thin;
        position: absolute;
        z-index: 5;
        text-align: left;
        color: #fff;
        width: 30%;
        top: 50%;
        left: 10%;
        font-size: 22px;
        transform: translate(0, -50%);
      }
    }
    .forthWrapper {
      background: #fff;
      .forthTitle {
        text-align: center;
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 50px;
      }
      .forthContent {
        width: 75%;
        margin: 24px auto;
        // background: green;
        .forthRow {
          display: flex;
          justify-content: space-between;
          margin-bottom: 120px;
          font-size: 15px;
          .forthRowItem {
            // height: 200px;
            img {
              width: 109px;
              height: 125px;
            }
            .itemTitle {
              position: static;
              font-size: 40px;
              // background: pink;
            }
            .itemBottomName {
              margin-top: 10px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .fifthWrapper {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        float: left;
      }
      .fifthTitle {
        width: 100%;
        position: absolute;
        color: #fff;
        font-size: 30px;
        text-align: center;
        margin-top: 30px;
      }
    }
    .sixWrapper {
      .sixTitle {
        margin-top: 50px;
        font-size: 30px;
      }
      .sixContent {
        width: 75%;
        margin: 0 auto;
        display: flex;
        .rightImg {
          width: 100%;
          // height: 400px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 479px) {
    .m_wrapper {
      .m_firstBannerWrapper {
        /deep/ .el-carousel__indicators{
          width: 80%;
        }
        img {
          /*设置图片宽度和浏览器宽度一致*/
          width: 100%;
          height: inherit;
        }
      }
      .m_secondWrapper {
        // background: chocolate;
        .secontTitle {
          text-align: center;
          font-size: 16px;
          margin-top: 40px;
          margin-bottom: 40px;
          font-family: PingFangSC-Semibold;
        }
        .secContent {
          width: 90%;
          margin: 18px auto;
          height: 180px;
          // background: green;
          .secTopRow {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            font-size: 12px;
            // background: #ae3938;
            .secItem {
              width: 90px;
              .showImage {
                width: 42px;
                height: 42px;
              }
            }
          }
          .secBottonRow {
            // background: green;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            margin-bottom: 10px;
            .secItem {
              width: 90px;
              .showImage {
                width: 42px;
                height: 42px;
              }
            }
          }
        }
      }
      .m_thirdTitle {
        text-align: center;
        width: 100%;
        height: 50px;
        color: #333;
        font-size: 16px;
        // background: chartreuse;
        font-family: PingFangSC-Semibold;
      }
      .m_thirdWrapper {
        overflow: hidden;
        position: relative;
        img {
          float: left;
          width: 100%;
          height: auto;
        }

        .thirdContent {
          font-family: PingFangSC-Thin;
          position: absolute;
          z-index: 5;
          text-align: left;
          color: #fff;
          width: 30%;
          top: 50%;
          left: 10%;
          font-size: 12px;
          transform: translate(0, -50%);
        }
      }
      .m_forthWrapper {
        background: #fff;
        .m_forthTitle {
          text-align: center;
          font-size: 16px;
          margin-top: 30px;
          margin-bottom: 30px;
          font-family: PingFangSC-Semibold;
        }
        .m_forthContent {
          width: 90%;
          margin: 24px auto;
          // background: green;
          .m_forthRow {
            display: flex;
            justify-content: space-between;
            font-size: 13px;
            .m_forthRowItem {
              height: 120px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              img {
                width: 44px;
                height: 44px;
              }
              .m_itemTitle {
                position: static;
                font-size: 30px;
                // background: pink;
              }
              .m_itemBottomName {
                margin-top: 10px;
                font-size: 12px;
                // background: green;
              }
            }
          }
        }
      }
      .m_fifthWrapper {
        padding-bottom: 20px;
        .m_fifthTitle {
          width: 100%;
          color: #333;
          font-size: 16px;
          text-align: center;
          margin-top: 40px;
          font-family: PingFangSC-Semibold;
          margin-bottom: 30px;
        }
        .m_fifthContent {
          margin: 0 auto;
          width: calc(100% - 20px);
          padding-left: 10px;
          padding-right: 10px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .m_fifthItem {  
            width: calc((100% - 10px) * 0.5);
            padding-top: 3px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>

